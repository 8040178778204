<template>
    <a-modal
      title="排序"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item  v-show="false" label="id">
          <a-input-number
            style="width: 100%"
            v-decorator="['id', { rules: [{ required: true, message: '' }] }]"
          />
        </a-form-item>
        <a-form-item label="排序">

          <a-input-number
            style="width: 100%"
            v-decorator="['sort', { rules: [{ required: true, message: '请输入排序号' }] }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
</template>

<script>
export default {
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'ddd' })
  },
  data () {
    return {
      confirmLoading: false,
      visible: false,
      carouselType: ''
    }
  },
  methods: {
    init (sortNum, id, carouselType) {
      this.visible = true
      setTimeout(() => {
        this.form.setFieldsValue({ sort: sortNum })
        this.form.setFieldsValue({ id: id })
      }, 100)
      this.carouselType = carouselType
    },
    handleCancel (e) {
      this.visible = false
    },
    async handleSubmit (e) {
      let that = this
      this.confirmLoading = true
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.editSort(values, function () { that.confirmLoading = false })
        }
      })
    },
    async editSort (values, callback) {
      let api = 'sortCarousel'
      if (this.carouselType === 'shop') {
        api = 'shopSortCarousel'
      }
      const res = await this.$store.dispatch('http', {
        api: api,
        query: {
          ...values
        },
        complete: () => {
          callback()
        }
      })
      if (res) {
        // 刷新列表
        this.handleCancel()
        this.$emit('sort')
      }
    }
  }
}
</script>

<style>

</style>
