<template>
  <a-modal :width="500" :class="$style.container" v-model="isShow" title="编辑"
  @ok="determine" @cancel="cancel" :maskClosable="false">
    <a-form-model ref="form" :rules="rules" :class="$style.form__ct" class="al-form" :label-col="{ style: 'width: 100px' }" :wrapper-col="{ style: 'width: 300px' }" layout="inline" :model="form">
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="轮播图名称:" style="margin-bottom:0">
          <span>{{form.name}}</span>
        </a-form-model-item>
        <a-form-model-item label="图标:" prop="imageUrl" style="margin-bottom:0">
            <ImageManage v-model="form.imageUrl" @change="$refs.form.validateField(['imageUrl'])" :square="false" :size="1024*1024*2" :showWidth="true">
                <div slot="extra">建议大小不超过200KB，建议尺寸：800*800像素，仅支持jpg、png格式</div>
            </ImageManage>
          <!-- <a-input v-model="form.imageUrl" placeholder="请输入" style="display:none"/> -->
          <!-- <a-upload name="file" list-type="picture-card" class="avatar-uploader avatar-uploader1"
            :show-upload-list="false" :action="$consts.uploadFileUrl"
            :headers="$store.state.header" :before-upload="(e)=>{beforeUpload(e)}" @change="(e)=>{handleChange(e)}">
            <a-avatar shape="square" v-if="form.imageUrl" :src="form.imageUrl" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload> -->
        </a-form-model-item>
        <a-form-model-item label="排序:" >
          <span>{{form.sort}}</span>
        </a-form-model-item>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import ImageManage from '@/components/imageManage/index.vue'
export default {
  components: {
    ImageManage
  },
  data () {
    return {
      overSize: true,
      loading: false,
      isShow: false, // 是否显示弹窗
      form: {
      },
      rules: {
        imageUrl: [
          { required: true, message: '图标不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    beforeUpload (file) {
      this.overSize = false
      // let isOverSize
      // isOverSize = file.size / 1024 < 200
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$store.dispatch('showToast', { type: 'warning', message: '图片格式不正确' })
      }

      // if (!isOverSize) {
      //   this.$store.dispatch('showToast', { type: 'warning', message: '图片超过了大小,请上传正确大小的图片' })
      //   this.overSize = true
      // }
      return false
    },
    async handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        if (!this.overSize) {
          this.form.imageUrl = info.file.response.result
        }
        this.loading = false
      }
    },
    cancel () {
      this.form = {
      }
    },
    determine (e) {
      delete this.form.gmtCreate
      delete this.form.gmtModified
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$store.dispatch('http', {
            api: 'editShopCarousel',
            query: {
              id: this.form.id
            },
            params: this.form,
            success: () => {
              this.cancel()
              this.$store.dispatch('showToast', { message: '编辑成功~' })
              this.isShow = false
              this.$emit('save')
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 120px;
  height: 120px;
}
.ant-upload-select-picture-card{
  margin:0 !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.avatar-uploader1 .ant-avatar{
  width:100px;
  height: 100px;
  margin: 0;
}
</style>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
