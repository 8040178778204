<template>
  <a-modal width="960px" :footer="null"  v-model="visible" title="商品选择">
    <a-form ref="form" layout="inline" :model="form" >
      <a-form-item label="名称:">
        <a-input v-model="form.name" placeholder="请输入" />
      </a-form-item>

       <a-form-item>
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
       </a-form-item>
    </a-form>
    <br/>
    <a-table :data-source="list"
             :rowKey="record => record.id"
             :columns="columns"
             :pagination="pagination"
             bordered>
      <div slot="action2"
           slot-scope="scope">
        <div>{{scope.goodsId}}</div>
      </div>
      <div slot="action3"
           slot-scope="scope">
        <a  @click="zoom(scope.img_url)">
            <a-avatar shape="square"
                  style="width:80px;height:80px;"
                  :src="scope.imgUrl" />
        </a>
      </div>
      <div slot="action4"
           slot-scope="scope">
        <div>{{scope.goodsName}}</div>
      </div>

      <div slot="action5"
           slot-scope="scope">
        <div>{{scope.goodsTypeName}}</div>
      </div>
      <span slot="action"
            slot-scope="app">
        <a @click="addCarousel(app)"><a-spin size="small" v-if="app.isLoading" />添加至电商轮播图</a>
      </span>
    </a-table>
  </a-modal>
</template>

<script>
export default {
  components: { },
  data () {
    return {
      visible: false,
      index: '',
      doctorImag: '',
      isQueryLoading: false,
      form: {
        name: ''
      },
      columns: [
        { title: 'SKU', width: 200, key: 'id', scopedSlots: { customRender: 'action2' } },
        { title: '图片', width: 100, key: 'imgUrl', scopedSlots: { customRender: 'action3' } },
        { title: '商品名称', width: 280, key: 'name', scopedSlots: { customRender: 'action4' } },
        { title: '分类', width: 80, key: 'nameGoyGoods', scopedSlots: { customRender: 'action5' } },
        { title: '操作', key: 'operation', width: 140, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
    },
    handleCancel (e) {
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        onsell: 1,
        idGouProducts: '8'
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'productPageManage',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.reloadList()
    },
    // 添加电商轮播图
    addCarousel (app) {
      const params = {
        idPageComponent: '1483284698867261441',
        name: app.goodsName,
        imageUrl: app.imgUrl,
        intent: 'open',
        resourceType: 'sku',
        identifier: app.productId
      }
      this.addCarouselConfirm(params)
    },
    // 确认添加
    async addCarouselConfirm (params) {
      await this.$store.dispatch('http', {
        api: 'addShopCarousel',
        params
      })
      this.$store.dispatch('showToast', { message: '添加成功~' })
      this.handleCancel()
      this.$emit('save')
    },
    cancel (values) {
      console.log(values)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
<style lang="scss" scope>
.ant-table-pagination {
    width:100% !important;
}
</style>
